.table {
  .fa {
    font-size: 1.3em;
  }
  thead {
    a {
      padding-left: 1rem;
      &:hover {
        text-decoration: none;
      }
    }
    a + a {
      padding-left: 0;
    }
  }
  tbody {
    a + a {
      padding-left: 1rem;
    }
  }
}

#consultants-table, #contacts-table {
  .column-is_active {
    width: 100px;
    .status {
      padding: 3px 10px;
      width: 100px;
      color: $c-white;
      &-active {
        background: green;
      }
      &-inactive {
        background: red;
      }
    }
  }
}

#consultants-table {
  .column-is_main_address {
    width: 140px;
  }
  .column-consultant_number {
    width: 200px;
  }
}

#reviews-table, #contacts-table {
  .column-is_visible {
    width: 120px;
  }
}

#consultants-table, #regulators-table, #reviews-table, #contacts-table {
  .column-id {
    width: 90px;
    text-align: center;
  }

  .btn-filter {
    .fa-refresh {
      font-size: 1.3rem;
      padding-right: 0.4rem;
    }
    &-reset {
      font-size: 0.9em;
    }
  }
}
