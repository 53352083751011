footer {
  margin-top: 5rem;
  .copyright-wrapper {
    background-color: $c-gold;
    color: $c-white;
    text-align: right;
    font-weight: bold;
    padding: 1rem 0;
    .container {
      padding-right: 0;
    }
  }
}
