// Fonts
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Droid+Serif);
@import "fontawesome/font-awesome";
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// Plansecur styles
@import "plansecur/header";
@import "plansecur/content";
@import "plansecur/table";
@import "plansecur/footer";

.panel {
  box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0;
}

.panel-default {
  border: none;
}

.panel-heading {
  font-weight: bold;
}

.panel-nav {
  .panel-body {
    padding: 0;
    ul {
      margin-bottom: 0;
      list-style: none;
      padding: 0;
      li {
        padding: 7px 15px;
        border-bottom: 1px solid $panel-default-border;
        &.current {
          a {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.form-section-headline {
  h4 {
    margin-top: 2.5em;
    text-transform: uppercase;
    border-bottom: 1px solid $c-black;
    padding-bottom: .3em;
    font-weight: bold;
    font-size: 1.1em;
  }
}

.form-btn-group {
  margin-top: 2em;
}

.alert-container {
  padding-left: 0;
  padding-right: 0;
  button.close {
    color: $c-white;
    opacity: 0.7;
    filter: alpha(opacity=70);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
}

.help-block {
  font-size: 14px;
  color: #777;
}

