.container-style {
  background-color: $c-white;
  padding-left: 25px;
  padding-right: 25px;
  h1 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    border-bottom: 2px solid $c-black;
    font-size: 1.15em;
    padding-bottom: 1rem;
  }

  @media (min-width: $grid-float-breakpoint) {
    -webkit-box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.25);
  }

}

.content-wrapper {
  .container {
    @extend .container-style;
  }
}

.content-container {
  @extend .container-style;
}

.content-wrapper .container, .content-container {
  .action-buttons {
    text-align: right;
    .btn {
      padding: 4px 8px;
      font-size: 14px;
      i.fa {
        padding-right: 3px;
        font-size: 12px;
      }
    }
  }
}
