.welcome-header {
  background-color: $c-lightgrey;
  border-bottom: 1px solid #9ea3a7;
  a {
    color: $c-black;
  }
  .navbar-nav {
    > li {
      .dropdown-menu {
        z-index: 2000;
      }
    }
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
  .nav {
    > li {
      a {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 0.9em;
        &:hover, &:focus {
          background-color: transparent;
        }
      }
    }
  }
}

.navbar-static-top {
  border-width: 0 0 3px;
}

.navbar-default {
  .navbar-toggle {
    margin-top: 30px;
    &:hover, &:focus {
      .icon-bar {
        background-color: $c-white;
      }
    }
  }
  .navbar-nav {
    > li > a {
      font-family: $font-family-serif;
    }
  }
}

.navbar-brand {
  padding: 25px 15px;
  height: auto;
  > img {
    height: 46px;
    padding: 0;
    width: 265px;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-brand {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-default {
    .navbar-nav {
      float: right;
      padding-top: 35px;
      > li > a {
        padding: 0 15px;
      }
    }
  }
}
