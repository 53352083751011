// Main CWP colours
$c-gold: #B88400;
$c-winered: #712D3D;
$c-green: #655D20;
$c-turquoise: #004E59;
$c-blue: #333E48;
$c-beige: #f7f8f3;
$c-white: #fff;
$c-black: #000;
$c-lightgrey: #e5e6e7;

// Body
$body-bg: $c-lightgrey;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $c-turquoise;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: $c-turquoise;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Quattrocento Sans", sans-serif;
$font-family-serif: "Droid Serif", "Times New Roman", Times, serif;

$font-size-base: 16px;
$line-height-base: 1.6;
$text-color: $c-black;

// Navbar
$navbar-default-bg: $c-white;
$navbar-default-color: $c-black;
$navbar-default-link-color: $c-black;
$navbar-default-link-hover-color: $c-gold;
$navbar-default-link-active-color: $c-gold;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: $brand-primary;
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: $c-white;

$navbar-default-toggle-border-color: $c-turquoise;
$navbar-default-toggle-icon-bar-bg: $c-turquoise;
$navbar-default-toggle-hover-bg: $c-turquoise;

// Alerts
$alert-success-bg: $c-turquoise;
$alert-success-border: $c-turquoise;
$alert-success-text: $c-white;

$alert-info-bg: $c-blue;
$alert-info-border: $c-blue;
$alert-info-text: $c-white;

$alert-warning-bg: $c-gold;
$alert-warning-border: $c-gold;
$alert-warning-text: $c-white;

$alert-danger-bg: $c-winered;
$alert-danger-border: $c-winered;
$alert-danger-text: $c-white;

$alert-padding: 10px 15px;
